footer {
  min-height: 60vh;
  background-color: var(--dim);
  position: relative;
  padding-top: 4rem;
  color: var(--color-white);
  padding-bottom: 2rem;
}

.search-bar form input[type="text"] {
  padding: 10px;
  font-size: 17px;
  border: 1.7px solid var(--color-white);
  float: left;
  width: 80%;
  background: #f1f1f1;
  height: 2.7rem;
}

.search-bar form .searchButton {
  float: left;
  width: 20%;
  height: 2.7rem;
  padding: 4.5px;
  background: var(--blue-dark-variant);
  color: var(--color-white);
  font-size: 17px;
  border: 1px solid var(--blue-dark-variant);
  border-left: none;
  cursor: pointer;
  text-align: center;
}

.search-bar form .searchButton .searchIcon {
  width: 2rem;
  height: 1.5rem;
  /* border: solid red 2px; */
}

.search-bar form button::after {
  content: "";
  clear: both;
  display: table;
}

footer .container .top {
  display: flex;
  gap: 4%;
  margin-bottom: 2rem;
  /* border: solid red 2px; */
}

footer .container .top .left {
  flex: 1;
  /* border: solid red 2px; */
}

footer .container .top .left .companyDetail .logo img {
  width: 70%;
}

footer .container .top .right {
  flex: 3;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  /* border: solid red 2px; */
}

footer .container .top .right .top {
  margin-top: 3rem;
}

footer .container .top .right .top .socials {
  display: flex;
  gap: 4%;
  justify-content: flex-end;
  width: 100%;
  padding-right: 15%;
  /* border: solid red 2px; */
}

footer .container .top .right .top .socials .icon {
  color: var(--color-white);
  width: 2rem;
  height: 2rem;
}

footer .container .top .right .top .socials .icon:hover {
  color: var(--sky-blue);
}

footer .container .top .right .bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  /* border: solid red 2px; */
}

footer .container .top .right .bottom .menuWrapper {
  display: flex;
  gap: 4%;
  justify-content: space-around;
  width: 100%;
  margin-top: 2rem;
  /* border: solid red 2px; */
}

footer .container .top .right .bottom .menuWrapper .header h3 {
  color: var(--yellow);
  text-transform: capitalize;
  font-family: days one;
  font-weight: 400;
}

footer .container .top .right .bottom .menuWrapper .menuList {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin-top: 0.8rem;
}

footer .container .top .right .bottom .menuWrapper .menuList a {
  color: var(--color-white);
  text-transform: capitalize;
}

footer .container .top .right .bottom .menuWrapper .menuList a:hover {
  color: var(--sky-blue);
}

footer .container .bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: solid red 2px; */
}

footer .container .bottom .search-bar {
  width: 30%;
  margin-bottom: 2rem;
  padding: 3rem 0;
}

footer .container .bottom .menu-items {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4%;
  width: 100%;
}

footer .container .bottom .menu-items a {
  color: var(--color-white);
  text-transform: capitalize;
}

footer .container .bottom .menu-items a:hover {
  color: var(--sky-blue);
}

footer .container .bottom .copyright {
  border-top: solid var(--color-white) 2px;
  width: 100%;
  padding-top: 2rem;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}

footer .container .bottom .copyright a {
  color: var(--color-white);
  text-transform: capitalize;
}

footer .container .bottom .copyright a:hover {
  color: var(--sky-blue);
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and(max-width:1024px) {
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  footer .container .top {
    flex-direction: column;
  }

  footer .container .top .left {
    width: 100%;
  }

  footer .container .top .left .companyDetail .logo img {
    width: 80%;
  }

  footer .container .top .right {
    width: 100%;
  }

  footer .container .top .right .top .socials {
    justify-content: space-between;
    padding-right: 0;
    /* border: solid red 2px; */
  }

  footer .container .top .right .top .socials .icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  footer .container .top .right .bottom .menuWrapper {
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    margin-top: 1rem;
    /* border: solid red 2px; */
  }

  footer .container .bottom .search-bar {
    width: 100%;
    margin-bottom: 1rem;
    padding: 2rem 0;
  }

  footer .container .bottom .menu-items {
    flex-direction: column;
    gap: 1rem;
  }
}
