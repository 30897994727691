.completion {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.completion h3,
.completion p {
  text-align: center;
}
