.rsvp {
  padding-top: 8rem;
  margin-bottom: 4rem;
}
.rsvp .header {
  display: inline-block;
  text-align: center;
  margin-top: 2px;
  width: 100%;
}
.left-align {
  text-align: left;
}

.rsvp .header h1 {
  font-size: 1.5rem;
  color: var(--blue-dark-variant);
  text-transform: uppercase;
}
.rsvp .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.formContent {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
form .formGroup {
  display: flex;
  width: 100%;
  margin-bottom: 1.2rem;
  gap: 2%;
}
.inputContainer {
  width: 100%;
  /* border: solid red 2px; */
}

.formContent form .formSingle {
  width: 100%;
  margin-bottom: 1.2rem;
}

.formContent form .formGroup input {
  border: 1px solid #ccc;
  width: 100%;
  padding: 0.7rem;
  border-radius: 0.3rem;
  background-color: #f0eff2;
  color: black;
}

.formContent form .formSingle input {
  border: 1px solid #ccc;
  width: 100%;
  padding: 0.7rem;
  border-radius: 0.3rem;
  background-color: #f0eff2;
  color: black;
}

.formContent form .formSingle select {
  border: 1px solid #ccc;
  width: 100%;
  padding: 0.7rem;
  border-radius: 0.3rem;
  background-color: #f0eff2;
  color: #636364;
}

.formContent form .formSingle select option:hover {
  color: var(--blue-dark-variant);
}

.formContent form textarea {
  border: 1px solid #ccc;
  width: 100%;
  padding: 0.7rem;
  border-radius: 0.3rem;
  /* margin-bottom: 1.2rem; */
  background-color: #f0eff2;
  color: black;
}

.formContent .btn-green {
  width: 100%;
}

.alertMsg {
  width: 100%;
  color: rgb(5, 185, 5);
  text-align: center;
  padding-bottom: 10px;
}

.error {
  color: red;
  margin-top: 5px;
  font-size: 0.9em;
}

.capitalize-text {
  text-transform: capitalize;
}

select.capitalize-text:disabled {
  appearance: none;
  background: none;
  border: none;
  font-size: inherit;
  font-weight: bold;
  color: inherit;
  padding: 0;
  margin: 0;
  width: 100%;
  cursor: default;
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width: 1024px) {
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  .modalContainer {
    width: 90%;
    height: 620px;
  }

  .modalContainer .header h1 {
    font-size: 1.2rem;
    color: var(--blue-dark-variant);
  }

  .formContent form .formGroup {
    flex-direction: column;
    margin-bottom: 0;
  }

  .formContent form .formGroup input {
    width: 100%;
    margin-bottom: 1.2rem;
  }
}
