.stripePayment {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.stripePayment input[type="number"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 1px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.stripePayment .error {
  color: #dc3545;
  margin-top: 10px;
}

.stripePayment .success {
  color: #28a745;
  margin-top: 10px;
}

.stripePayment form {
  max-width: 400px;
  margin: 0 auto;
}

.stripePayment .StripeElement {
  background-color: white;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.stripePayment .StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.stripePayment button {
  background-color: var(--blue-dark-variant);
  color: #ffffff;
  padding: 12px 16px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  width: 100%;
  margin: 20px auto 0;
  transition: all 0.2s ease;
}

.stripePayment button:hover {
  filter: brightness(110%);
}

.stripePayment button:disabled {
  opacity: 0.5;
  cursor: default;
}
