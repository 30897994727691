@import url("https://fonts.googleapis.com/css2?family=Days+One&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

:root {
  --red: #d92108;
  --red-variant: #ef4b33;
  --red2: #b5192a;
  --orange-red: #f66c3b;
  --yellow: #fdae38;
  --brown: #684c41;
  --deep-brown: #3a2a2c;
  --grey-deep: #5c5c5c;
  --green-dark: #4fa3a5;
  --blue-dark: #0b5c77;
  --blue-dark-variant: #0f7794;
  --sky-blue: #58d9ff;
  --dim: #3b3857;
  --color-white: #fff;
  --color-light: #fafbff;
  --transition: all 400ms ease;
  --light-color: rgba(#fff, 0.7);
  --black-color: #0c031b;
  --dark-color: rgba(var(--black-color), 0.7);
  --container-lg: 82%;
  --container-md: 90%;
  --transition: all 400ms ease;
}

#root {
  white-space: pre-line;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: sans-serif, "poppins";
  line-height: 1.5;
  color: var(--dark-color);

  background-color: var(--color-white);
  /* user-select: none; */
  overflow-x: hidden;
  position: relative;

  overscroll-behavior-y: none;
}

.container {
  width: var(--container-lg);
  margin: 0 auto;
}

img {
  display: block;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.2;
}

h1 {
  font-family: days one;
  text-transform: capitalize;
}

section h2 {
  text-align: center;
}

section p {
  width: 100%;
  margin: 0.6rem auto 2.5rem;
}

.pt-6 {
  padding-top: 6rem;
}

.btn-white {
  min-width: 8rem;
  text-align: center;
  text-transform: capitalize;
  display: inline-block;
  width: fit-content;
  padding: 0.55rem 1rem;
  border-radius: 1rem;
  background: #fff;
  color: #000;
  font-weight: 500;
  letter-spacing: 0.2rem;
  cursor: pointer;
  transition: all 400ms ease;
}

.btn-white:hover {
  background: lighten(#fff, 8%);
  color: #000;
  box-shadow: 0 1rem 1.6rem rgba(#fff, 0.15);
  transform: scale(0.94);
}

.btnPrimary {
  min-width: 8rem;
  text-align: center;
  text-transform: capitalize;
  display: inline-block;
  width: fit-content;
  padding: 0.55rem 1rem;
  border-radius: 1rem;
  background: var(--blue-dark-variant);
  color: var(--color-white);
  font-weight: 500;
  cursor: pointer;
  transition: all 400ms ease;
}

.btnPrimary:hover {
  background: lighten(#0f7794, 8%);
  color: var(--color-white);
  box-shadow: 0 1rem 1.6rem rgba(#0f7794, 0.15);
  transform: scale(0.94);
}

.btnSecondary {
  min-width: 8rem;
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
  width: fit-content;
  padding: 0.55rem 1rem;
  border-radius: 1rem;
  background: var(--yellow);
  color: var(--color-white);
  font-weight: 500;
  cursor: pointer;
  font-weight: 700;
  transition: all 400ms ease;
}

.btnSecondary:hover {
  background: lighten(#fdae38, 8%);
  color: var(--color-white);
  box-shadow: 0 1rem 1.6rem rgba(#fdae38, 0.15);
  transform: scale(0.94);
}

.grey-color {
  background: var(--grey);
}

.donateNavBtn,
.donateNavBtn:focus {
  background: var(--red-variant);
  padding: 6px 20px;
  border-radius: 1rem;
  color: var(--color-white);
  transition: all 400ms ease;
}

.donateNavBtn:hover,
.donateNavBtn:focus:hover {
  color: var(--color-white);
  background: var(--color-light);
  transform: scale(0.94);
}

.footerDonateNavBtn {
  background: var(--blue-dark-variant);
  padding: 4px 20px;
  border-radius: 1rem;
  color: var(--red);
  transition: all 400ms ease;
}

.footerDonateNavBtn:hover,
.footerDonateNavBtn:focus:hover {
  transform: scale(0.94);
}

.video__overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background: rgba(0, 165, 75, 0.1);
  background-image: linear-gradient(transparent, #00a54b);
  z-index: 2;
}

#bgVid {
  position: absolute;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: 1;
}

.header h4 {
  text-transform: lowercase;
  font-weight: 600;
  font-size: 2rem;
  color: var(--light-blue);
}

.title h1 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 3.5rem;
  color: var(--sea-blue);
}

.hor {
  display: flex;
  gap: 4%;
}

.ver {
  flex: 1;
}

@media screen and (max-width: 600px) {
  .header h4 {
    text-transform: lowercase;
    font-weight: 600;
    font-size: x-large;
    color: var(--light-blue);
  }

  .title h1 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: xx-large;
    color: var(--sea-blue);
  }

  .hor {
    flex-direction: column;
  }
}
