.titleSection h1 {
  font-size: 3.4rem;
  color: var(--red-variant);
  font-weight: 500;
  text-transform: uppercase;
}

.programList ol li {
  list-style: decimal !important;
}

.programList ol li::marker {
  font-size: 18px;
  font-weight: bold;
}

.programList ol li h3 {
  font-size: 20px;
  font-family: days one;
  font-weight: 400;
}

.program-link {
  color: var(--blue-dark);
  text-decoration: none;
}

@media (max-width: 991px) {
  .titleSection h1 {
    font-size: 2.5rem;
  }
}
