.navbar {
  width: 100vw;
  height: 5rem;
  position: fixed;
  z-index: 10;
  display: grid;
  place-items: center;
  background-color: var(--green-dark);
  z-index: 99999;
  /* box-shadow: 0 1rem 1rem rgba(#0c031b, 0.1); */
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.17);
}

.navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar .container .nav__items {
  display: flex;
  gap: 2.5rem;
  align-items: center;
  justify-content: center;
}

.navbar .container .nav__items .nav__item {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.navbar .container #open__nav-btn {
  display: none;
}

.navbar .container .nav__items a {
  color: var(--color-white);
  font-weight: 400;
  font-size: 0.9rem;
  position: relative;
  text-transform: capitalize;
}

.navbar .container ul .donateNavBtn {
  color: var(--color-white);
  text-transform: capitalize;
}

.navbar .container .nav__items a:hover {
  color: var(--color-white);
}

.navbar .container ul .donateNavBtn:hover {
  color: var(--red);
}

.navbar .container .nav__items .active {
  color: var(--color-white);
}

.navbar .container .nav__items .active::after {
  color: var(--color-white);
  width: 50%;
}

.navbar .container ul .donateNavBtn .active::after {
  display: none;
}

.navbar .container .nav__items a::after {
  content: "";
  width: 0;
  height: 3px;
  position: absolute;
  bottom: -5px;
  left: 25%;
  transform: translateX(-50%);
  background: var(--color-white);
  transition: width 0.3s;
}

.navbar ul .donateNavBtn::after {
  display: none;
}

.navbar .container .nav__items a:hover::after {
  width: 50%;
}

/* .navbar .donateNavBtn.active {
  color: var(--red) !important;
} */

.navbar .donateNavBtnActive {
  color: var(--dim);
  padding: 10px 20px;
  border-radius: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 400ms ease;
  background-color: var(--blue-dark);
}
.navbar .donateNavBtnActive:hover {
  transform: scale(0.94);
}

.navbar .container ul .donateNavBtnActive::after {
  display: none;
}

.navbar .container .nav__logo img {
  width: 6rem;
}

.navbar .container .nav__items .donateNavBtn {
  background-color: var(--red);
  color: var(--color-white);
  padding: 10px 20px;
  border-radius: 25px;
  text-transform: uppercase;
  font-weight: 600;
  transition: all 0.3s ease;
}

.navbar .container .nav__items .donateNavBtn:hover {
  color: var(--color-white);
}

.navbar .container .nav__items .donateNavBtn:active {
  transform: scale(0.95);
}

.navbar .container .nav__items .donateNavBtn.active {
  background-color: var(--blue-dark);
  color: var(--red);
}

/* sub menu */

.nav__item-with-submenu {
  position: relative;
}

.submenu {
  display: none;
  background-color: var(--yellow);
  padding: 10px;
  border-radius: 0 0 5px 5px;
  z-index: 1000;
  min-width: 150px;
}

/* .nav__item-with-submenu:hover .submenu {
  display: block;
} */

/* Show submenu on hover for desktop */
@media screen and (min-width: 1025px) {
  .nav__item-with-submenu:hover .submenu {
    display: block;
  }

  .submenu {
    position: absolute;
    top: 100%;
    left: 0;
  }

  .nav__item-with-submenu:hover .submenu {
    display: block;
  }
}

.submenu li {
  margin: 5px 0;
}

.submenu a {
  color: var(--color-white);
  font-size: 0.8rem;
  padding: 5px 10px;
  display: block;
  white-space: nowrap;
}

.submenu a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width: 1024px) {
  .navbar {
    position: fixed;
  }

  .navbar .container #open__nav-btn {
    display: inline-block;
    background: transparent;
    color: var(--color-white);
    font-size: 1.5rem;
    font-weight: 200;
    cursor: pointer;
  }

  .navbar .container .nav__items .donateNavBtn {
    width: auto;
    margin: 0;
    width: 40%;
  }

  .navbar .container .nav__items {
    position: fixed;
    top: 5rem;
    left: -100%;
    width: 100%;
    height: calc(100vh - 5rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(5px);
    /* border-radius: 0 0 15px 15px; */

    margin: 0;
    z-index: -1;
    transition: all 0.3s ease-in-out;
    align-items: flex-start;
    /* padding-left: 20%; */
    overflow-y: auto;
  }

  .navbar .container .nav__items .nav__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    /* margin-bottom: 2rem; */
    /* width: 100%; */
    /* border: solid red 2px; */
  }

  .navbar .container .show__nav {
    display: flex;
    align-items: center;
    left: 0%;
    padding: 20px 0 0 0;
  }

  .navbar .container .hide__nav {
    display: none;
  }

  .navbar .container .nav__items a {
    color: var(--blue-dark-variant);
    font-size: large;
    /* border: solid blue 2px; */
  }

  .navbar .container .nav__items li {
    display: block;
    padding: 0rem 0;
    /* border: solid red 2px; */
  }

  .navbar .container .nav__items .donateNavBtn .active {
    color: var(--red) !important;
  }

  .navbar .container .nav__items .active {
    color: var(--dim);
  }

  .navbar.container .nav__logo img {
    width: 8rem;
  }

  .submenu {
    position: static;
    background-color: transparent;
    padding-left: 20px;
    display: none;
  }

  .nav__item-with-submenu > a {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav__item-with-submenu > a::after {
    content: "▼";
    font-size: 0.8em;
    margin-left: 5px;
  }

  .show-submenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.4rem;
  }
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  .navbar .container .nav__logo img {
    width: 6rem;
  }

  .navbar .container .nav__items .active {
    color: var(--red);
  }

  .navbar .donateNavBtnActive.active {
    color: var(--color-white) !important;
  }

  .navbar .container .nav__items a:hover {
    color: var(--red);
  }

  .navbar .container ul .donateNavBtn:hover {
    color: var(--color-white);
  }

  .navbar .container .nav__items .active::after {
    color: var(--red);
  }
}
