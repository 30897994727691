.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgba(18, 18, 18, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  z-index: 99999;
  top: 0;
  left: 50%;
  transform: translateY(-50%);
  transform: translateX(-50%);
}

.modalContainer {
  width: 800px;
  min-height: 600px;
  border-radius: 12px;
  background-color: var(--color-white);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 25px;
  overflow-y: auto;
  transition: bottom 0.3s ease;
  bottom: -100%;
  animation: fadeIn 0.3s ease-in-out;
}

.modalContainer::-webkit-scrollbar {
  width: 0; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.modalContainer.active {
  bottom: 0;
  /* border: solid blue 2px; */
}

.jobViewModalBkg {
  display: none;
}

.modalContainer.job-view {
  /* display: none; */
  min-height: 100%;
  width: 600px;
  /* border: solid green 2px; */
  margin: 0 2%;
}
.bipoc {
  width: 600px;
  min-height: 200px;
}
.content-wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.modalContainer .closeBtn {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 20px;
  margin-bottom: 1rem;

  /* border: solid red 2px; */
}

.modalContainer .closeBtn button {
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.modalContainer .header {
  display: inline-block;
  text-align: center;
  margin-top: 2px;
}

.modalContainer .header h1 {
  font-size: 1.2rem;
  color: var(--blue-dark-variant);
}

.modalContainer .modalContent {
  /* padding-top: 1rem; */
  height: 100%;
  width: 100%;
  /* border: solid red 2px; */
}

.modalContainer .center {
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  height: 100%;
  overflow-y: scroll;
}
.modalContainer .center::-webkit-scrollbar {
  width: 0; /* Hide scrollbar for Chrome, Safari, and Opera */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modalContent.center {
  animation: fadeIn 0.5s ease-in-out;
}
.form-wrapper {
  height: 100%;
  overflow-y: scroll;
  margin-bottom: 1rem;
}
.modalContainer .modalContent form {
  margin-bottom: 0.5rem;
}
.modalContainer .modalContent form .formGroup {
  display: flex;
  width: 100%;
  margin-bottom: 1.2rem;
  gap: 2%;
}

.modalContainer .modalContent form .formSingle {
  display: flex;
  width: 100%;
  margin-bottom: 1.2rem;
}

.modalContainer .modalContent form .formGroup input {
  border: solid #f0eff2 1px;
  width: 80%;
  padding: 0.7rem;
  border-radius: 0.3rem;
  background-color: #f0eff2;
  color: black;
}

.modalContainer .modalContent form .formSingle input {
  border: solid #f0eff2 1px;
  width: 100%;
  padding: 0.7rem;
  border-radius: 0.3rem;
  background-color: #f0eff2;
  color: black;
}

.modalContainer .modalContent form .formSingle select {
  border: solid #f0eff2 1px;
  width: 100%;
  padding: 0.7rem;
  border-radius: 0.3rem;
  background-color: #f0eff2;
  color: #636364;
}

.modalContainer .modalContent form .formSingle select option:hover {
  color: var(--blue-dark-variant);
}

.modalContainer .modalContent form textarea {
  border: solid #f0eff2 1px;
  width: 100%;
  padding: 0.7rem;
  border-radius: 0.3rem;
  margin-bottom: 1.2rem;
  background-color: #f0eff2;
  color: black;
}

.modalContainer .modalContent .btn-green {
  width: 100%;
}

.alertMsg {
  width: 100%;
  color: rgb(5, 185, 5);
  text-align: center;
  padding-bottom: 10px;
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width: 1024px) {
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  .modalContainer {
    width: 90%;
    height: 620px;
    justify-content: flex-start;
  }

  .bipoc {
    height: 400px !important;
  }

  .modalContainer .header h1 {
    font-size: 1.2rem;
    color: var(--blue-dark-variant);
  }

  .modalContainer .modalContent form .formGroup {
    flex-direction: column;
    margin-bottom: 0;
  }

  .modalContainer .modalContent form .formGroup input {
    width: 100%;
    margin-bottom: 1.2rem;
  }

  .jobViewModalBkg {
    display: flex;
  }
  .modalContainer.job-view {
    min-height: 350px !important;
    width: 500px;
    /* border: solid blue 2px; */
  }
}
