.job-filter-tabs-container {
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent tabs from wrapping */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  /* border: solid red 2px; */
}
.job-filter-tabs-container::-webkit-scrollbar {
  width: 0; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.job-filter-tabs {
  display: inline-flex; /* Display tabs in a single row */
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .job-filter-tabs {
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
}

.filter-tab {
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #f5f4f4;
  transition: background-color 0.3s ease;
  white-space: nowrap;
  margin: 3px;
}

.filter-tab.active {
  background-color: var(--blue-dark);
  color: white;
}

.job-search-app {
  max-width: 80%;
  margin: 0 auto;
  padding: 20px;
  padding-top: 8rem;
}

.job-search-app h1 {
  color: var(--blue-dark);
  text-transform: capitalize;
}

.job-search-content {
  display: flex;
  flex-direction: column;
}

.job-search-input {
  margin-bottom: 10px;
  padding: 16px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  margin: 1rem 0;
  background-color: white;
  box-shadow: 0 1rem 1.6rem rgba(#000, 0.15);
}

hr {
  border: 1px solid var(--blue-dark);
}

.line-light {
  border: 1px dashed #f0f0f0;
}

.jobs-wrapper {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
  position: relative;
}

.jobs-wrapper h3,
.jobs-wrapper h2 {
  color: var(--blue-dark);
}

.job-listing {
  /* border: 1px solid #ccc; */
  width: 40%;
}

.job-item {
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.pagination button {
  border: 1px solid #ccc;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #f0f0f0;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination button:hover {
  background-color: #ddd;
}

.pagination button .icon {
  font-size: 1.5rem;
  font-weight: bold;
}

.selected-job {
  width: 60%;
  background-color: #ffffff;
  z-index: 1;
  margin-bottom: 20px;
}

.sticky-contents {
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  overflow-wrap: break-word;
  /* background-color: #fbfbfb; */
  transition: background-color 0.3s ease;
  transition: var(--transition);
}

.job-details {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  transition: var(--transition);
}

.job-details .profile-avatar {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 3px;
  width: 200px;
  height: 200px;
  margin-bottom: 1rem;
}

.job-details .profile-avatar img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.detail-info {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.detail-info-item .icon {
  color: #ccc;
  font-size: 13px;
}

.detail-info-item .profession {
  color: #656363;
}
.bio {
  color: #3e3d3d;
}

@media screen and (max-width: 600px) {
  .job-search-app {
    max-width: 100%;
  }
  .jobs-wrapper {
    flex-direction: column;
  }
  .job-listing {
    width: 100%;
  }
  .selected-job {
    width: 100%;
  }
  .displayNoneMob {
    display: none;
  }

  .job-details .profile-avatar {
    width: 100%;
    height: 200px;
  }
  .job-details .profile-avatar img {
    object-fit: contain;
  }
}
