.home-skeleton {
  width: 100%;
  min-height: 90vh;
}
.showcase-skeleton {
  min-height: 90vh;
}
.showcase-skeleton .container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
}

.showcase-skeleton .txtContent {
  width: 100%;
}

.skeleton-text-head {
  height: 40px;
  background-color: #e0e0e0;
  margin-bottom: 10px;
  border-radius: 4px;
  width: 60%;
}

.skeleton-text-sub {
  height: 20px;
  width: 20%;
  background-color: #e0e0e0;
  margin-bottom: 10px;
  border-radius: 4px;
}

.skeleton-text {
  height: 10px;
  width: 100%;
  background-color: #e0e0e0;
  margin-bottom: 10px;
  border-radius: 4px;
}

.skeleton-title {
  width: 70%;
  height: 40px;
  margin-bottom: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
}

.skeleton-paragraph {
  width: 100%;
  height: 16px;
  margin-bottom: 10px;
  background-color: #e0e0e0;
  border-radius: 4px;
}

@media (max-width: 991px) {
  .skeleton-title {
    height: 30px;
  }
}

.skeleton-image {
  width: 100%;
  height: 300px;
  background-color: #e0e0e0;
  border-radius: 8px;
}

@media screen and (max-width: 600px) {
  .skeleton-image {
    height: 200px;
  }
}

.home-skeleton .btnHolder {
  position: relative;
  display: flex;
  justify-content: flex-end;
  z-index: 3;
}
.skeleton-button {
  width: 120px;
  height: 30px;
  background-color: #e0e0e0;
  border-radius: 20px;
}

.hor {
  display: flex;
  justify-content: space-between;
  gap: 4%;
  width: 100%;
}

.ver {
  flex: 1;
  padding: 20px;
  width: 100%;
}

/* animation */
@keyframes loading {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}

.skeleton-gallery-item,
.skeleton-video,
.skeleton-date,
.skeleton-icon,
.skeleton-input,
.skeleton-map,
.skeleton-tabs,
.skeleton-subtitle,
.skeleton-list-item,
.skeleton-card,
.skeleton-card-icon,
.skeleton-card-title,
.skeleton-card-text,
.skeleton-text-head,
.skeleton-text-sub,
.skeleton-text,
.skeleton-image,
.skeleton-title,
.skeleton-paragraph,
.skeleton-button {
  position: relative;
  overflow: hidden;
  background-color: #e0e0e0;
}

.skeleton-video::before,
.skeleton-gallery-item::before,
.skeleton-date::before,
.skeleton-icon::before,
.skeleton-input::before,
.skeleton-map::before,
.skeleton-tabs::before,
.skeleton-subtitle::before,
.skeleton-list-item::before,
.skeleton-card::before,
.skeleton-card-icon::before,
.skeleton-card-title::before,
.skeleton-card-text::before,
.skeleton-text-head::before,
.skeleton-text-sub::before,
.skeleton-text::before,
.skeleton-title::before,
.skeleton-paragraph::before,
.skeleton-image::before,
.skeleton-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200px 100%;
  animation: loading 3s infinite linear;
}

.skeleton-tabs {
  display: flex;
  margin-bottom: 20px;
}

.skeleton-tab {
  width: 120px;
  height: 40px;
  margin-right: 10px;
  border-radius: 4px;
}

.skeleton-subtitle {
  height: 40px;
  margin-bottom: 20px;
}

.skeleton-list-item {
  height: 30px;
  margin-bottom: 10px;
}

.skeleton-card {
  height: 300px;
  border-radius: 8px;
  padding: 20px;
}

.skeleton-card-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.skeleton-card-title {
  height: 30px;
  margin-bottom: 15px;
}

.skeleton-card-text {
  height: 20px;
  margin-bottom: 10px;
}

.skeleton-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.skeleton-input {
  height: 40px;
  width: 100%;
  margin-bottom: 15px;
}

.skeleton-map {
  height: 300px;
  width: 100%;
}

.skeleton-date {
  height: 80px;
  width: 80px;
}

.skeleton-slide {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.skeleton-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (min-width: 768px) {
  .skeleton-slide {
    flex-direction: row;
  }

  .skeleton-image {
    width: 50%;
  }

  .skeleton-content {
    width: 50%;
  }
}

.skeleton-video {
  height: 400px;
  width: 100%;
  margin: 20px 0;
}

.skeleton-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  /* border: solid red 2px; */
}

.skeleton-gallery-item {
  height: 150px;
  width: calc(33.333% - 10px);
}

@media (max-width: 768px) {
  .skeleton-gallery-item {
    width: calc(50% - 10px);
  }
}

@media (max-width: 480px) {
  .skeleton-gallery-item {
    width: 100%;
  }
}
