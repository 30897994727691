.contactTop h1 {
    font-family: days one;
    font-size: 55px;
    color: var(--blue-dark-variant);
    font-weight: 500;
    text-transform: unset;
}

.socialContainer {
    background-color: var(--color-white);
    padding: 1rem;
    box-shadow: -4px 3px 12px #c5c5c5, 4px -3px 12px #fbfbfb;
}

.contactFormContainer {
    background-color: var(--blue-dark);
    width: 100%;
    padding: 1rem;
    color: var(--color-white);
}

.contactFormContainer form {
    background-color: var(--blue-dark-variant);
    padding: 2rem 1rem;
}

.contactFormContainer .input-field {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.contactFormContainer input {
    width: 100%;
}

.input-field input {
    font-size: 15px;
    background-color: transparent;
    color: var(--color-light);
    border-bottom: 1px solid var(--color-light);
    padding-bottom: 5px;
}

.input-field input:focus+label,
.input-field input:focus+label {
    color: var(--color-white);
}

.input-field input::placeholder {
    color: var(--light-color);
}

.input-field input:focus,
.input-field input[type="password"]:focus {
    border-bottom: 1px solid var(--color-white);
    box-shadow: 0 1px 0 0 var(--color-white);
}

.input-field input.valid,
.input-field input[type="password"].valid {
    border-bottom: 1px solid #2196f3;
    box-shadow: 0 1px 0 0 #2196f3;
}

.input-field input.invalid,
.input-field input[type="password"].invalid {
    border-bottom: 1px solid #f44336;
    box-shadow: 0 1px 0 0 #f44336;
}

.input-field .prefix.active {
    color: #000;
}

.submitBtn {
    background-color: var(--color-white);
    border-radius: 30px;
    padding: 0.5rem 1.5rem;
    font-size: 15px;
    font-weight: bolder;
    cursor: pointer;
    color: var(--blue-dark);
}

.submitBtn:hover {
    background-color: var(--blue-dark);
    color: var(--color-white);
}

/* -0.3rem 0rem 0.5rem rgba(0, 0, 0, 0.15) !important */