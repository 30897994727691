.eventDetailSection {
  /* border: solid red 2px; */
  padding-top: 8rem;
}

.eventDetailSection .eventHeader {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
  min-height: 50vh;
  background-color: #f3f3f3;
  /* border: solid red 2px; */
}

.eventDetailSection .eventHeader .flex {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: solid red 2px; */
}

.eventDetailSection .eventHeader .eventTitle {
  width: 100%;
  padding-left: 5%;
  /* border: solid blue 2px; */
}

.eventDetailSection .eventHeader .eventTitle h1 {
  width: 100%;
  text-transform: capitalize;
  color: var(--color-fulfillment);
  font-size: xx-large;
  /* border: solid red 2px; */
}

.eventHeader .imgWrapper {
  height: 300px;
  width: 300px;
}

.eventHeader .imgWrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.eventVideo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  /* border: solid red 2px; */
}

/* .eventVideo iframe {
  border: solid red 2px;
  width: 100%;
} */

.eventPictures {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
}

.eventPictures .header h3 {
  font-family: days one;
  font-size: xx-large;
  color: var(--color-fulfillment);
  margin-bottom: 2rem;
}
