.upcomingDetailHeader {
  min-height: 50vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 5rem;
}

.upcomingHeader {
  background-color: var(--red);
}

.blurBkg {
  min-height: 50vh;
  background-color: rgba(233, 165, 37, 0.5);
  backdrop-filter: blur(10px) grayscale(20%);
  /* border: solid red 2px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.upcomingDetailHeader .eventHeader {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
  color: var(--color-white);
  /* border: solid red 2px; */
}

.upcomingDetailHeader .eventHeader .flex {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: solid red 2px; */
}

.upcomingDetailHeader .eventHeader .left {
  justify-content: center;
  align-items: flex-start;
}

.upcomingDetailHeader .eventHeader .eventTitle {
  width: 100%;

  /* border: solid blue 2px; */
}

.upcomingDetailHeader .eventHeader .eventTitle h1 {
  width: 100%;
  text-transform: capitalize;
  color: var(--color-white);
  font-size: xx-large;
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
  /* border: solid red 2px; */
}

.eventHeader .imgWrapper {
  height: 300px;
  width: 300px;
}

.eventHeader .imgWrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
/* .eventInfo {
  border: solid blue 2px;
} */
.eventInfo .infoItemD {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  height: 3rem;
  /* border: solid yellow 2px; */
}
.eventInfo .infoItemD .icon {
  margin-right: 1rem;
}
.eventInfo .infoItemD p {
  text-align: left;
  width: 100%;
  /* border: solid red 2px; */
}

/* .detailWrapper {
  padding: 1% 5%;
} */

.eventDetailContent {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 1rem;
}
.eventNotice {
  font-style: italic;
  color: var(--red);
  margin-bottom: 1rem;
}
.eventInfo {
  margin-bottom: 2rem;
}
.eventDate,
.eventTime,
.eventLocation {
  margin-bottom: 0.5rem;
}

.eventActions {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.backLink {
  color: var(--red);
}

@media (max-width: 768px) {
  .eventActions {
    flex-direction: column;
  }
}
