.showcase {
  min-height: 90vh;
}

.showcase .container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hor {
  justify-content: center;
  align-items: center;
}

.blueDark {
  color: var(--blue-dark);
  font-size: 5rem;
}

.red {
  color: var(--red);
  font-size: 5rem;
}

.brown {
  color: var(--brown) !important;
}

.dimBkg {
  background-color: var(--dim) !important;
}

.showcase .txtContent {
  margin-top: 1rem;
}

.showcase .txtContent h3 {
  font-family: days one;
  text-transform: capitalize;
  font-size: x-large;
  color: var(--green-dark);
}

.showcase .content .header {
  line-height: 0.1rem;
}

.btnHolder {
  position: relative;
  display: flex;
  justify-content: flex-end;
  z-index: 3;
}

.mothering {
  min-height: 70vh;
  background-color: var(--brown);
  position: relative;
}

.content {
  padding: 4rem 0;
}

.mothering .content .header h1 {
  color: var(--yellow);
  font-size: 4rem;
  text-align: right;
}

.mothering .justify-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mothering .content .txtContent {
  position: relative;
  color: var(--color-white);
  text-align: right;
}

.bold {
  font-weight: 800;
  font-size: x-large;
}

.mothering .offsetImg .imgWrapper {
  left: -1%;
}

.mothering .offsetImg .imgWrapper img {
  width: 45rem;
}

.empowered {
  min-height: 90vh;
  background-color: var(--red-variant);
  position: relative;
}

.empowered .container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
}

.empowered .container .content h1 {
  color: var(--yellow);
  font-size: 4rem;
  line-height: 2.5rem;
}

.empowered .btnHolder {
  justify-content: flex-start;
}

.offsetImg .imgWrapper {
  position: absolute;
  bottom: 0;
  z-index: 1;
}

.support {
  min-height: 30vh;
  background-color: var(--blue-dark-variant);
  color: var(--color-white);
}

.support .container .hor .ver .content {
  padding: 1rem 0;
  /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; */
}

.support .imgWrapper img {
  width: 12rem;
}

.support .header h1 {
  color: var(--yellow);
  text-transform: capitalize;
  font-size: xx-large;
}

.bigBtn {
  justify-content: center;
}

.bigBtn .btnSecondary {
  height: 5rem;
  min-width: 16rem;
  border-radius: 5rem;
  color: var(--black-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
  font-family: days one;
  font-weight: normal;
}

.mediaMembership {
  min-height: 35vh;
  background-color: var(--blue-dark-variant);
  color: var(--color-white);
  position: relative;
}

/* .mediaMembership .imgWrapper {
    right: -1%;
} */
.mediaMembership .container .hor {
  .mediaMembership .container .ver {
    border: solid red 2px;
    height: 100%;
  }

  /* justify-content: flex-start; */
  align-items: flex-start;
  padding-top: 2rem;
}

.mediaMembership .container .ver {
  /* border: solid red 2px; */
  height: 100%;
}

.mediaMembership .content {
  padding: 0;
}

.mediaMembership .imgWrapper img {
  width: 100%;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.mediaMembership .header h1 {
  color: var(--yellow);
  text-transform: capitalize;
  font-size: xx-large;
}

.girlGroup {
  min-height: 20vh;
  background-color: var(--color-white);
  position: relative;
}

.girlGroup .imgWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.girlGroup .imgWrapper img {
  width: 40%;
}

.buildingTogether {
  min-height: 100vh;
  padding: 3rem 0;
}

.buildingTogether .container {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buildingTogether .content {
  text-align: left;
}

.buildingTogether .content .header h1 {
  color: var(--blue-dark-variant);
  font-size: 5rem;
  text-transform: capitalize;
}

.buildingTogether .content .txtContent h3 {
  font-family: days one;
  text-transform: capitalize;
  font-size: x-large;
  margin-top: 2rem;
}

.buildingTogether .bottom {
  margin-bottom: 3rem;
  width: 100%;
  /* border: solid red 2px; */
}

.buildingTogether .partnerLogos {
  padding: 3rem 0;
  /* border: solid red 2px; */
}

.buildingTogether .ceoWrapper {
  margin-bottom: 5rem;
}

.buildingTogether .ceoWrapper .content {
  text-align: left;
}

.buildingTogether .ceoWrapper .content .btnHolder {
  justify-content: flex-start;
}

.buildingTogether .ceoWrapper .imgWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buildingTogether .ceoWrapper .imgWrapper img {
  width: 80%;
}

.buildingTogether .partnerLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: solid red 2px; */
}

.buildingTogether .partnerLogos .partnerLogo .imgWrapper {
  /* border: solid red 2px; */
  height: 5rem;
  width: 5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.buildingTogether .partnerLogos .partnerLogo .imgWrapper img {
  /* border: solid red 2px; */
  height: 100%;
  width: 100%;

  object-fit: contain;
}

.joinSection {
  position: relative;
  min-height: 50vh;
  background-color: var(--dim);
}

.joinSection .offsetImg .imgWrapper img {
  width: 70%;
}

.joinSection .content {
  color: var(--color-white);
}

.joinSection .content .btnHolder {
  justify-content: flex-start;
}

.joinSection .content h1 {
  color: var(--yellow);
  text-transform: capitalize;
  font-size: 4rem;
}

/* .success {
  min-height: 100vh;
  background-color: var(--blue-dark-variant);
  padding: 3rem 0;
}

.success .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.success .header h1 {
  text-transform: capitalize;
  font-size: 4rem;
  color: var(--sky-blue);
  text-align: center;
}

.success .header h3 {
  text-transform: capitalize;
  font-size: xx-large;
  color: var(--color-white);
  font-family: days one;
  font-weight: normal;
  margin-top: 1rem;
  text-align: center;
}

.success .membersWrapper {
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}

.success .membersWrapper .member {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3%;
  width: 70%;
  min-height: 15rem;
  margin-bottom: 2rem;

}

.success .membersWrapper .reversed {
  flex-direction: row-reverse;
}

.success .membersWrapper .member .imageContainer {
  flex: 1;
  height: 15rem;
  width: 15rem;
  border-radius: 2rem;
  background-color: var(--color-white);
  position: relative;

}

.success .membersWrapper .member .imageContainer img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
  border-radius: 2rem;
  position: absolute;
}

.success .membersWrapper .member .memberInfo {
  flex: 2;
  
} */

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width: 1024px) {
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/
@media screen and (max-width: 600px) {
  .blueDark {
    font-size: 4rem;
  }

  .red {
    font-size: 4rem;
  }

  .btnHolder {
    justify-content: flex-start;
  }

  .mothering {
    min-height: 70vh;
    background-color: var(--brown);
    position: relative;
  }

  .mothering .container .hor {
    flex-direction: column-reverse;
  }

  .content {
    padding: 4rem 0;
  }

  .mothering .content .header h1 {
    font-size: 3rem;
  }

  .mothering .offsetImg .imgWrapper {
    left: 0;
  }

  .mothering .offsetImg .imgWrapper img {
    width: 100%;
  }

  .empowered {
    min-height: 90vh;
  }

  .empowered .container {
    flex-direction: column;
  }

  .empowered .container .content h1 {
    font-size: 3rem;
  }

  .offsetImg .imgWrapper {
    position: relative;
  }

  .support {
    padding: 2rem 0;
  }

  .girlGroup .imgWrapper img {
    width: 100%;
  }

  .buildingTogether .container {
    width: 82%;
  }

  .buildingTogether .content .header h1 {
    font-size: 3rem;
  }

  .buildingTogether .bottom {
    margin-bottom: 0;
  }

  .buildingTogether .ceoWrapper {
    margin-bottom: 0;
  }

  .buildingTogether .ceoWrapper .hor {
    flex-direction: column-reverse;
  }

  .buildingTogether .ceoWrapper .imgWrapper img {
    width: 100%;
  }

  .joinSection .offsetImg .imgWrapper img {
    width: 100%;
  }

  .joinSection .content h1 {
    font-size: 3rem;
  }

  .success .header h1 {
    font-size: 3rem;
  }

  .success .membersWrapper .member {
    flex-direction: column-reverse;

    width: 100%;
  }

  .success .membersWrapper .reversed {
    flex-direction: column-reverse;
  }
}
