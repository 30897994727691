.cta-button-wrapper {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 9;
}

.cta-button {
  padding: 10px 20px;
  background-color: #ff6600;
  color: #ffffff;
  border-radius: 5rem;
  border: none;
  font-size: 16px;
  cursor: pointer;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  transition: all 400ms ease;
  overflow: hidden;
  position: relative;
}

.cta-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  border-radius: 15px;
  background-color: #fdae38;
  z-index: -1;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  transition: all 250ms;
  /* animation: expandWidth 0.9s ease-in-out forwards; */
}

@keyframes expandWidth {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.cta-button:hover {
  color: #e8e8e8;
  transform: scale(0.94);
}

.cta-button:hover::before {
  width: 100%;
}
