.membershipHeader {
  min-height: 70vh;
  padding-top: 6rem;
  position: relative;
}

.membershipHeader .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.membershipHeader .container .heroContent {
  height: 70vh;
  width: 100%;
  background: url("./../../assets/images/Group-121.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.membershipHeader .container .heroContent .txtContent {
  /* border: solid red 2px; */
  margin-bottom: 7rem;
}

.membershipHeader .container .heroContent .txtContent h3 {
  font-family: days one;
  font-size: large;
  color: var(--blue-dark-variant);
}

.family {
  min-height: 90vh;
  padding: 2rem 0;
  background-color: var(--dim);
  color: var(--color-white);
}

.family h1 {
  font-size: 3.5rem;
  text-transform: capitalize;
}

.family h3 {
  font-family: days one;
  font-weight: 400;
  font-size: x-large;
  margin-top: 1rem;
  color: var(--sky-blue);
}

.family .btnHolder {
  justify-content: flex-start;
}

.family .imgWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.family .imgWrapper img {
  width: 80%;
}

.volunteer {
  min-height: 90vh;
  padding: 2rem 0;
  background-color: var(--yellow);
  color: var(--black-color);
}

.volunteer h1 {
  font-size: 3.5rem;
  text-transform: capitalize;
  color: var(--red);
}

.volunteer h3 {
  font-family: days one;
  font-weight: 400;
  font-size: x-large;
  margin-top: 1rem;
}

.volunteer .btnHolder {
  justify-content: flex-start;
}

.volunteer .imgWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.volunteer .imgWrapper img {
  width: 80%;
}

@media screen and (max-width: 600px) {
  .membershipHeader {
    min-height: 40vh;
  }

  .membershipHeader .container .heroContent {
    height: 40vh;
  }

  .membershipHeader .container .heroContent .txtContent {
    margin-bottom: 5rem;
  }

  .family h1 {
    font-size: 3rem;
  }

  .family .imgWrapper img {
    width: 90%;
  }

  .volunteer h1 {
    font-size: 3rem;
  }

  .volunteer .hor {
    flex-direction: column-reverse;
  }

  .volunteer .imgWrapper img {
    width: 90%;
  }
}
