h2 {
    text-align: unset !important;
}

.helloSection img.img-fluid,
.ourLogo img.img-fluid {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.helloText {
    text-transform: capitalize;
    font-size: calc(1.5rem + 2.5vw);
    font-weight: 600;
    color: var(--blue-dark);
    font-family: days one;
    letter-spacing: 0.5rem;
    margin-top: 1rem;
}

.ourLogo h1 {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--green-dark);
    text-transform: unset;
    font-family: unset;
}

.attributes {
    margin-top: 7rem;
    margin-bottom: 2rem;
}

.attributes h2 {
    font-family: days one;
    text-transform: capitalize;
    font-weight: 500;
}

.attributes p {
    letter-spacing: 0.05rem;
}

.attributes .mission {
    background-color: var(--blue-dark-variant);
    color: var(--color-white);
}

.attributes p.missionStmt {
    width: 87%;
    margin-left: 0;
}

.attributes .mission h2 {
    text-transform: capitalize;
}

.attributes .vision {
    background-color: var(--orange-red);
    color: var(--color-white);
}

.attributes .values {
    background-color: var(--brown);
    color: var(--color-white);
}

.attributes .values p {
    letter-spacing: 0;
}

.attributeImg {
    position: absolute;
    left: -3.5rem;
    bottom: -7.6rem;
}

.attributeImg2 {
    position: absolute;
    left: 0;
    bottom: -3rem;
}

.attributeImg3 {
    position: absolute;
    left: 2rem;
    bottom: -1.5rem;
    height: 230px;
    width: 260px;
}

.architects {
    background-color: var(--blue-dark);
    color: var(--color-white);
}

.architects h1 {
    color: var(--sky-blue);
    font-family: days one;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.architects h3 {
    font-family: sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--color-white);
    letter-spacing: 0.005rem;
}

.architects .memberImg {
    border-radius: 20px;
    /* background-color: var(--color-white); */
    /* border: 1px solid grey; */
    /* height: 100%; */
    /* height: 210px; */
    width: 100%;
}

.architects .memberImg img {
    height: 100%;
    object-fit: contain;
    border: 1px solid grey;
}

.architects .member:nth-child(2) .memberImg {
    width: 281px;
}

.architects .member:nth-child(1) .memberImg img {
    height: 244px;
}

.architects .member:nth-child(2) .memberImg img {
    height: 260px;
    border-radius: 20px;
    max-width: 100%;
    object-fit: cover;
}

.architects .memberImg img.millicent2 {
    object-fit: cover !important;
    border-radius: 20px;
    height: 225px;
}

.architects .member:nth-child(3) .memberImg img {
    height: 260px;
}

.architects .members h2 {
    font-weight: 500;
    font-size: 1.3rem;
    color: var(--yellow);
}

.architects .members h4 {
    font-weight: 400;
    font-size: 0.9rem;
    color: var(--color-white);
    margin-top: 0
}

.rightContent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: end;
}

.rightContent>p {
    text-align: end;
}

#con {
    margin: 0px;
    /* background: #acc; */
}

#con>div {
    position: relative;
    /* background: #ccd; */
    height: 25px;
}

#con>div:nth-child(1) {
    margin-top: 15px;
}

#con>div:last-child {
    height: 0px;
}

#con>div:nth-child(1)::before {
    float: left;
    content: "\00a0";
    width: 0px;
    height: 51px;
}

#con>div:nth-child(4)::before {
    float: left;
    content: "\00a0";
    width: 90px;
    height: 51px;
}

#con>div:last-child::before {
    width: 85px;
    height: 51px;
}

#con>div:before {
    float: left;
    content: "\00a0";
    width: 80px;
    height: 51px;
}

#con>div>p {
    display: inline-block;
    height: 20px;
    width: fit-content;
    /* background: #000; */
    /* color: #fff; */
}

@media (min-width: 1600px) {
    .attributeImg {
        bottom: -9.5rem;
    }
}

@media (max-width: 1600px) {
    .attributeImg {
        bottom: -8.75rem;
    }
}

@media (max-width: 1400px) {
    .attributeImg {
        bottom: -8rem;
    }
}

@media (max-width: 1300px) {
    .attributeImg {
        bottom: -7.75rem;
    }
}

@media (max-width: 1200px) {
    .attributeImg {
        bottom: -7.2rem;
    }
}

@media (max-width: 1100px) {
    .attributeImg {
        bottom: -6.8rem;
    }
}

@media (min-width: 991px) and (max-width: 1080px) {
    .attributeImg {
        bottom: -6.5rem;
    }

}

@media (max-width: 991px) {
    .helloText {
        font-size: 2.2rem;
    }

    .architects h1 {
        font-size: 1.8rem;
    }

    .architects h3 {
        font-size: 1.2rem;
    }

}

@media (max-width: 576px) {
    .rightContent {
        align-items: start;
    }


    .rightContent p {
        text-align: start;
    }

}

@media (max-width: 486px) {
    #con>div:before {
        width: 50px;
    }

    #con>div:nth-child(4)::before {

        width: 60px;
    }
}

@media (max-width: 378px) {
    #con>div:before {
        width: 30px;
    }

    #con>div:nth-child(4)::before {

        width: 40px;
    }

    #con>div:last-child::before {
        width: 65px;
    }
}